import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/components/MainPage.vue';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Set the document title dynamically based on the route's meta title
router.beforeEach((to, from, next) => {
  document.title = '1200Bookshop';
  next();
});

export default router;

