<template>
  <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
    <div
      v-for="(article, index) in articles"
      :key="index"
      class="group relative overflow-hidden lg:rounded-lg sm:rounded-none shadow-lg transition-all hover:shadow-xl animate__animated animate__fadeInUp"
    >
      <a :href="article.article_link" target="_blank">
        <img
          :src="article.image_link"
          :alt="article.title"
          width="200"
          height="300"
          class="h-[300px] w-full object-cover transition-all group-hover:scale-105"
          style="aspect-ratio: 200 / 300;"
        />
        <div class="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
        <div class="absolute bottom-0 left-0 right-0 px-4 py-3 text-left">
          <h3 
            class="text-lg font-semibold text-gray-100 mb-1 truncated-title hover:text-gray-50" 
            :title="article.title" 
            >
              {{ article.title }}
            </h3>
          <!-- <p class="text-sm text-white/80">{{ article.description }}</p> -->
        </div>
      </a>
    </div>
  </div>
</template>
  
<script>
import 'animate.css';
import api from '../api'; // 导入封装好的API模块

export default {
  name: "NewsPage",
  data() {
    return {
      articles: [],
    };
  },
  methods: {
    async loadArticles() {
      // get articles
      const response = await api.getArticles();
      if (response.data.result && response.data.data.length > 0) {
        this.articles = response.data.data; // 更新数据
      } else {
        console.log("data length: 0");
        this.articles = [];
      }
      // add footer
      // this.addFooter();
    },
  },
  mounted() {
    this.loadArticles();
  }
};
</script>
  
<style src="../style/icons.css">

</style>
