<template>
  <div class="flex flex-col min-h-screen bg-gray-200 overflow-hidden">
    <!-- Banner -->
    <Banner />
    <main class="container mx-auto py-12 md:py-16">
      <div class="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-4">
        <!-- Content -->
        <div class="col-span-1 md:col-span-2 lg:col-span-3">
          <component :is="currentComponent" />
        </div>

        <!-- Sidebar -->
        <Sidebar
          :selectedCategory="selectedCategory"
          @update:selectedCategory="handleCategoryChange"
        />
      </div>
    </main>
    <!-- Footer -->
    <TheFooter />
    
  </div>
</template>

<script>
import 'animate.css';
import Banner from '@/components/Banner.vue';  // 导入 Banner 组件
import News from '@/components/News.vue';  // 导入 News 组件
import About from '@/components/About.vue';  // 导入 About 组件
import Message from '@/components/Message.vue';  // 导入 About 组件
import Sidebar from '@/components/Sidebar.vue';  // 导入 Sidebar 组件
import TheFooter from '@/components/Footer.vue';  // 导入 Footer 组件

export default {
  data() {
    return {
      selectedCategory: 'news'  // 用于存储当前选中的类别
    };
  },
  components: {
    News,  // 注册 News 组件
    Message,  // 注册 Message 组件
    About,  // 注册 About 组件
    Sidebar, // 注册 Sidebar 组件
    Banner,  // 注册 Banner 组件
    TheFooter,
  },
  computed: {
    currentComponent() {
      // 根据 selectedCategory 返回相应的组件
      switch (this.selectedCategory) {
        case 'about':
          return 'About';
        case 'message':
          return 'Message';
        case 'news':
        default:
          return 'News';
      }
    }
  },
  methods: {
    handleCategoryChange(category) {
      this.selectedCategory = category;  // 更新选中的类别
      console.log(this.selectedCategory);
    },
  },
  mounted() {
  }
};
</script>

<style>
.container {
  max-width: 1200px;
}

.truncated-title {
  display: inline-block;
  max-width: 22ch; /* 默认最大长度为 22 个字符 */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 在小屏幕下设置 max-width 为 48ch */
@media (max-width: 768px) {
  .truncated-title {
    max-width: 48ch; /* 小屏幕下最大长度为 48 个字符 */
  }
}

/* 隐藏滚动条，但保留滚动功能 */
body {
  overflow-y: scroll; /* 确保有滚动条的空间 */
}

body::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 (适用于 WebKit 浏览器，如 Chrome, Safari) */
}

body {
  -ms-overflow-style: none;  /* IE 和 Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>
