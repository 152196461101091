<template>
  <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
  <div
    v-for="(bookshop, index) in bookshops"
    :key="index"
    class="group relative overflow-hidden lg:rounded-lg sm:rounded-none shadow-lg transition-all hover:shadow-xl animate__animated animate__fadeInUp"
  >
    <a @click="showInfo(bookshop, getImageUrl(bookshop.image))" class="cursor-pointer">
      <img
        :src="getImageUrl(bookshop.image)"
        width="200"
        height="300"
        class="h-[300px] w-full object-cover transition-all group-hover:scale-105"
        style="aspect-ratio: 200 / 300;"
      />
      <div class="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
      <div class="absolute bottom-0 left-0 right-0 px-4 py-3 text-left transition-all transform">
        <h3
          class="text-lg font-semibold text-gray-100 mb-1 truncated-title hover:text-gray-50 transition-all"
          :title="bookshop.name"
        >
          {{ bookshop.name }}
        </h3>
        <p class="text-sm text-white/80">{{ bookshop.addr }}</p> <!-- 显示地址 -->
      </div>
    </a>
  </div>
  <ShopInfoModal 
    :isOpen="isModalOpen" 
    :content="modalContent" 
    :image="modalImage" 
    @close="handleCloseModal"
  />
  </div>
</template>

<script>
import 'animate.css';
import ShopInfoModal from '@/components/ShopInfoModal.vue';  // 导入 ShopInfoModal 组件
import api from '../api'; // 导入封装好的API模块

export default {
  name: "AboutPage",
  components: {
    ShopInfoModal, // 注册 ShopInfoModal 组件
  },
  data() {
    return {
      // bookshop info
      bookshops: [],
      isModalOpen: false,
      modalContent: {},
      modalImage: "",
    };
  },
  methods: {
    getImageUrl(imageName) {
      try {
        const images = require.context('@/assets/img', false, /\.(png|jpe?g|svg)$/);
        return images(`./${imageName}`);
      } catch (error) {
        console.error(`Failed to load image at path: ${imageName}`, error);
        return '';
      }
    },
    showInfo(content, image) {
      this.modalContent = content;
      this.modalImage = image;
      this.openModal();
    },
    openModal() {
      this.isModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
    },
    async getBookshopInfo() {
      try {
        const response = await api.getBookshops();
        if (response.data.result && response.data.data.length > 0) {
          this.bookshops = response.data.data;
        } else {
          console.log("获取书店信息失败");
          this.bookshops = [];
        }
      } catch (error) {
        console.error("Failed to fetch bookshop information:", error);
        this.bookshops = [];
      }
    }
  },
  mounted() {
    this.getBookshopInfo();
  },
};
</script>

<style>
.container {
  max-width: 1200px;
}

.truncated-title {
  display: inline-block;
  max-width: 24ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .truncated-title {
    max-width: 48ch;
  }
}
</style>
