<template>
  <footer class="bg-muted py-6 text-xs text-gray-600" id="footer">
    <span id="footer_note"></span> 
    &nbsp;|&nbsp;
    <span id="footer_social_media">
      <span class="text-green-700 hover:text-green-600 font-bold cursor-pointer" @click="showMedia('wechat')">
        <i class="wechat align-top" />
        <span>微信</span>
      </span>
      &nbsp;|&nbsp;
      <span class="text-red-700 hover:text-red-600 font-bold cursor-pointer" @click="showMedia('xiaohongshu')">
        <i class="xiaohongshu align-top" />
        <span>小红书</span>
      </span>
      
    </span>
  </footer>
  <ImageModal 
    :isOpen="isModalOpen" 
    :title="modalTitle"
    :image="modalImage" 
    @close="handleCloseModal"
  />
</template>
  
<script>
import ImageModal from '@/components/ImageModal.vue';  // 导入 ShopInfoModal 组件

export default {
  name: "TheFooter",
  components: {
    ImageModal
  },
  data() {
    return {
      isModalOpen: false,
      modalTitle: '',
      modalImage: '',
      footer_note: '© Copyright 2024 1200 Bookshop All Rights Reserved',
      xiaohongshu_link: 'https://www.xiaohongshu.com/user/profile/65b20c410000000007005451',
    };
  },
  methods: {
    addFooterNote() {
      var footer_note_element = document.getElementById('footer_note');
      footer_note_element.innerHTML = this.footer_note;
    },
    showMedia(platform){
      if (platform=="xiaohongshu") {
        window.open(this.xiaohongshu_link, '_blank');
      } else if (platform=="wechat") {
        this.modalImage = "wechat.png";
        this.modalTitle = "扫码关注公众号";
        this.openModal();
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    handleCloseModal(){
      this.isModalOpen = false;
    }
  },
  mounted() {
    this.addFooterNote();
  }
};

</script>
  
<style src="../style/icons.css"></style>
