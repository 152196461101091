<template>
  <transition
    name="modal-fade"
    @enter="enter"
  >
    <div
      v-if="isOpen"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50"
      @click="closeModal"
    >
      <div
        id="shop_info_modal_window"
        class="bg-gray-100 rounded-lg p-1 shadow-lg lg:w-1/2 w-full max-w-2xl flex relative animate_duration_short animate__animated"
        @click.stop
      >
        <button
          @click="closeModal"
          class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div class="flex-1">
          <img
            :src="image"
            alt="Shop Image"
            class="w-full h-full object-cover rounded-lg"
          />
        </div>

        <div class="flex-1 pr-6">
          <h2 class="text-xl font-bold mb-4 text-left text-red-800 mt-4 ml-6">
            <a :href="content.link" target="_blank">
              1200Bookshop {{ content.name }}
            </a>
          </h2>
          <div class="mt-8">
            <p class="mb-5 ml-6 text-left text-gray-700 text-sm">
              {{ content.description }}
            </p>
            <p class="mb-4 ml-6 text-left">
              <label class="text-red-800 font-bold">
                <i class="material-symbols--event align-top" />
                营业时间：
              </label>
              <span class="text-gray-700">{{ content.opening_hours }}</span>
            </p>
            <div class="mb-4 ml-6 text-left">
              <label class="text-red-800 font-bold">
                <i class="solar--lightbulb-bolt-linear align-top" />
                主营：
              </label>
              <span
                v-for="tag in content.main_subject"
                :key="tag"
                class="text-gray-700"
              >
                #{{ tag }}
              </span>
            </div>
            <p class="mb-4 ml-6 text-left">
              <label class="text-red-800 font-bold">
                <i class="iconamoon--location align-top" />
                地址：
              </label>
              <span class="text-gray-700">{{ content.addr }}</span>
            </p>
            
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'animate.css';

export default {
  name: 'ShopInfoModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    image: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    enter(el) {
      const modal_element = el.querySelector('#shop_info_modal_window');
      if (modal_element) {
        modal_element.classList.add('animate__zoomIn');
      }
    },
  },
};
</script>

<style>
.animate_duration_short {
  animation-duration: 0.5s;
}
</style>
