<template>
  <header class="py-8 md:py-12 bg-red-800">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-12">
        <div class="space-y-4 mt-10">
          <h1 class="text-4xl font-bold text-primary-foreground md:text-5xl text-rose-200 text-left ml-2 animate__animated animate__fadeIn">1200Bookshop</h1>
          <p class="text-lg text-primary-foreground md:text-xl text-rose-300 text-left ml-3 animate__animated animate__fadeInDown">
            试着赞美这个残缺的世界
          </p>
        </div>
        <img
          :src="banner"
          alt="Bookshop"
          width="500"
          height="300"
          class="object-cover lg:rounded-lg sm:rounded-none"
          style="aspect-ratio: 500 / 300;"
        />
      </div>
    </div>
  </header>
</template>
  
<script>
import 'animate.css';

export default {
  name: "TheBanner",
  data() {
    return {
      banner: require('@/assets/img/1200banner.jpg'),
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>
  
<style src="../style/icons.css">

</style>