import axios from 'axios';

const api = axios.create({
baseURL: '/',
  timeout: 10000, // 请求超时设定
});

export default {
  getArticles() {
    return api.post('/api/get_articles');
  },
  getBookshops() {
    return api.post('/api/get_bookshops');
  },
  getMessages() {
    return api.post('/api/get_messages');
  }
};
