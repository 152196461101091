<template>
  <div
    v-for="(message, index) in messages"
    :key="index"
    class="message p-5 border-b-4 border-red-800 animate__animated animate__fadeInUp relative mb-4 cursor-default"
  >
    <div class="flex items-center space-x-2">
      <p class="font-semibold text-gray-600">
        {{ message.username }}:
      </p>
      <p class='text-gray-600'>{{ message.content }}</p>
    </div>
    <small class="text-gray-500 absolute bottom-1 right-2 text-xs">
      {{ message.time }}
    </small>
  </div>
</template>

<script>
import 'animate.css';
import api from '../api'; // 导入封装好的API模块

export default {
  name: "MessageBoard",
  data() {
    return {
      messages: [],
    };
  },
  methods: {
    async getMessage() {
      const response = await api.getMessages();
      if (response.data.result && response.data.data.length > 0) {
        this.messages = response.data.data; // 更新数据
      } else {
        console.log("data length: 0");
        this.messages = [];
      }
    },
  },
  mounted() {
    this.getMessage();
  }
};
</script>

<style scoped>
</style>
