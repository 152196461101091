<template>
<transition
  name="modal-fade"
  @enter="enter"
>
  <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30" @click="closeModal">
    <div 
      id="ImageModal"
      class="bg-white p-6 rounded-lg lg:w-1/6 md:w-1/3 sm:w-3/4 animate__animated" 
      @click.stop>
      <h2 class="text-xl text-left font-bold text-red-800">{{ title }}</h2>
      <div class="m-6 flex justify-center items-center">
        <slot>
            <img
                :src="getImageUrl(image)"
                alt="image"
                class="object-cover"
            />
        </slot> 
      </div>
      <div class="flex justify-end space-x-2">
        <button 
          @click="closeModal" 
          class="px-4 py-1.5 rounded text-gray-100 bg-gray-700 hover:bg-gray-800 hover:text-gray-50"
        >
          关闭
        </button>
      </div>
    </div>
  </div>
</transition>
</template>
  
<script>
import 'animate.css';

export default {
  name: 'ImageModal', // 修改为多词名称
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '模态弹窗',
    },
    image: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('close'); // 触发关闭事件，让父组件控制弹窗的显示状态
    },
    enter(el) {
      const modal_element = el.querySelector('#ImageModal');
      if (modal_element) {
        modal_element.classList.add('animate__slideInUp');
      }
    },
    getImageUrl(imageName) {
      try {
        const images = require.context('@/assets/img', false, /\.(png|jpe?g|svg)$/);
        return images(`./${imageName}`);
      } catch (error) {
        console.error(`Failed to load image at path: ${imageName}`, error);
        return '';
      }
    },
  }
};
</script>
  
<style scoped>
/* 可选的额外样式 */
</style>
  